import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';

import { useStaticQuery, graphql } from 'gatsby';

const Image = ({ name, className, style }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/images/" } }) {
        edges {
          node {
            name
            absolutePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(() => {
    const exactMatch = data.allFile.edges.find(({ node }) => node.name === name);
    return exactMatch || data.allFile.edges.find(({ node }) => node.absolutePath.includes(name))
  }, [name, data]);

  return <Img style={style} fluid={match.node.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className={className} alt="No image" />
};

Image.defaultProps = {
  className: '',
  style: {}
};

Image.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default Image;
